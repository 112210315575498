import useDebounce from '@app/Hooks/useDebounce';
import {
  create_product_group,
  get_product_groups
} from '@app/pages/product-groups/redux/reducers';
import {
  getProductGroups,
  getLoadingProductGroups
} from '@app/pages/product-groups/redux/selectors';
import { create_units, get_units } from '@app/pages/unit/redux/reducers';
import { getUnits, getLoading } from '@app/pages/unit/redux/selectors';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { create_ingredients, update_ingredient } from '../redux/reducer';
import { ImSpinner } from 'react-icons/im';
import { creatingIngredientsLoading } from '../redux/selector';

const AddIngredients = ({ isOpen, onClose, name, list, edit }) => {
  const [show, setShow] = useState('');
  const units = useSelector(getUnits);
  const loading = useSelector(getLoading);
  const dispatch = useDispatch();
  const [searchString, setSearchString] = useState('');
  const [search, setSearch] = useState('');
  const searchGroup = useDebounce(search, 300);
  const debouncedTerm = useDebounce(searchString, 300);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const productGroups = useSelector(getProductGroups);
  const creating = useSelector(creatingIngredientsLoading);
  const loadingProductGroups = useSelector(getLoadingProductGroups);

  useEffect(() => {
    dispatch(get_units({ limit: 50 }));
  }, [dispatch, debouncedTerm]);

  useEffect(() => {
    dispatch(get_product_groups({ searchString: searchGroup }));
  }, [dispatch, searchGroup]);

  let initialValues = {
    name: name || list?.name || '',
    description: list?.description || '',
    unit: list?.unit?.id || '',
    product_group: list?.product_group?.id || ''
  };

  const onSubmit = (doc) => {
    if (!doc.unit) {
      toast.error('Unit is Required');
      return;
    }
    if (!doc.product_group) {
      toast.error('Unit is Required');
      return;
    }
    let data = Object.keys(doc).reduce((acc, key) => {
      if (doc[key] !== '' && doc[key] !== undefined) {
        acc[key] = doc[key];
      }
      return acc;
    }, {});

    if (!edit) {
      dispatch(create_ingredients({ data, onClose }));
    } else {
      dispatch(update_ingredient({ data, id: list?._id, onClose }));
    }
  };

  const handleSearchUnit = (doc) => {
    setSearchString(doc);
  };

  const handleSearchGroup = (doc) => {
    setSearch(doc);
  };

  const createProductGroup = () => {
    let data = {
      name: search,
      description: 'a new description'
    };

    dispatch(create_product_group(data));
  };

  const createUnit = () => {
    let data = {
      name: searchString
    };

    dispatch(create_units(data));
  };

  const filteredUnits = units?.filter((item) =>
    item?.name?.toLowerCase()?.includes(debouncedTerm?.toLowerCase())
  );
  return (
    <Modal
      title={edit ? 'Edit Ingredient' : 'Create Ingredient'}
      isOpen={isOpen}
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
    >
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            onSubmit(values);
          }}
          enableReinitialize={true}
        >
          {({ values, handleChange, setFieldValue, handleSubmit }) => (
            <Form className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <label className="text-[#14151A] text-[14px] font-medium">
                  Name <span className="text-[12px] text-red-500">*</span>
                </label>
                <Input
                  name="name"
                  handleChange={handleChange}
                  value={values.name}
                  placeholder={'Name'}
                  isRequired={true}
                />
              </div>

              <div className="flex flex-col gap-1">
                <label className="text-[#14151A] text-[14px] font-medium">
                  Description
                </label>
                <TextArea
                  name="description"
                  handleChange={handleChange}
                  value={values.description}
                  placeholder={'Description'}
                  isRequired={false}
                />
              </div>

              <div className="flex flex-col gap-1">
                <label className="text-[#14151A] text-[14px] font-medium">
                  Unit <span className="text-[12px] text-red-500">*</span>
                </label>
                <Select
                  title="Unit"
                  handleSearch={(e) => handleSearchUnit(e.target.value)}
                  trigger={() => setShow((prev) => (prev === 'unit' ? '' : 'unit'))}
                  show={show}
                  placeholder={'Search for unit'}
                  data={filteredUnits}
                  selected={selectedUnit || list?.unit?.name}
                  createFN={createUnit}
                  value={searchString}
                  onSelect={(data) => {
                    setSelectedUnit(data.name);
                    setFieldValue('unit', data._id);
                    setShow('');
                  }}
                  loading={loading}
                  name="unit"
                />
              </div>

              <div className="flex flex-col gap-1">
                <label className="text-[#14151A] text-[14px] font-medium">
                  Product Group <span className="text-[12px] text-red-500">*</span>
                </label>
                <Select
                  title="Product Group"
                  name="product_group"
                  handleSearch={(e) => handleSearchGroup(e.target.value)}
                  trigger={() =>
                    setShow((prev) =>
                      prev === 'product_group' ? '' : 'product_group'
                    )
                  }
                  show={show}
                  value={search}
                  createFN={createProductGroup}
                  placeholder={'Search for Product Group'}
                  data={productGroups}
                  selected={selectedGroup || list?.product_group?.name}
                  onSelect={(data) => {
                    setSelectedGroup(data.name);
                    setFieldValue('product_group', data._id);
                    setShow('');
                  }}
                  loading={loadingProductGroups}
                />
              </div>

              <div>
                <button
                  type="button"
                  className="grid place-items-center"
                  onClick={handleSubmit}
                  style={{ width: '100%' }}
                >
                  {creating ? <ImSpinner className="animate-spin" /> : 'Submit'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddIngredients;

const Modal = ({ children, title, isOpen, onClose, onClick }) => {
  if (!isOpen) return null;
  return (
    <div onClick={onClick}>
      <div
        className={`fixed  inset-0  bg-[#14151A80] duration-700 ease-in-out opacity-50 z-[60]  h-screen w-full`}
      />
      <div
        onClick={onClick}
        className={`max-w-[640px] w-full fixed duration-700 ease-in-out top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-md bg-white z-[70] p-4 rounded-[6px]`}
      >
        <div>
          <div className="flex items-center justify-between mb-4">
            <p className="text-[#14151A] font-semibold text-[20px]">{title}</p>
            <div
              onClick={onClose}
              className="w-6 h-6 rounded-full bg-[#0A0F290A] grid place-items-center cursor-pointer"
            >
              {closeIcon}
            </div>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

const Input = ({ name, handleChange, value, isRequired, placeholder, type }) => {
  return (
    <input
      type={type || 'text'}
      name={name}
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      required={isRequired}
      className="border-[1px] border-[#DEE0E3] text-sm font-normal placeholder:text-[#0D112666] rounded-md p-2 w-full outline-none duration-700 ease-in-out focus:border-[#003333] focus:border-opacity-50 focus:shadow-[#003333] focus:ring focus:ring-[#ACD1D1] focus:ring-opacity-30"
    />
  );
};

const TextArea = ({ name, handleChange, value, isRequired, placeholder }) => {
  return (
    <textarea
      type="text"
      rows={5}
      name={name}
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      required={isRequired}
      className="border-[1px] border-[#DEE0E3] text-sm font-normal placeholder:text-[#0D112666] rounded-md p-2 w-full outline-none duration-700 ease-in-out focus:border-[#003333] focus:border-opacity-50 focus:shadow-[#003333] focus:ring focus:ring-[#ACD1D1] focus:ring-opacity-30"
    />
  );
};

const closeIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99993 6.17461L9.88743 3.28711L10.7123 4.11194L7.82476 6.99944L10.7123 9.88694L9.88743 10.7118L6.99993 7.82428L4.11243 10.7118L3.2876 9.88694L6.1751 6.99944L3.2876 4.11194L4.11243 3.28711L6.99993 6.17461Z"
      fill="#0F1324"
      fill-opacity="0.6"
    />
  </svg>
);

export const Select = ({
  selected,
  title,
  trigger,
  show,
  handleSearch,
  placeholder,
  data,
  onSelect,
  loading,
  name,
  value,
  createFN
}) => {
  return (
    <div className="relative  w-full">
      <div
        onClick={trigger}
        className="border-[1px] border-[#DEE0E3] rounded-md p-2 w-full cursor-pointer text-[14px] font-normal"
      >
        {selected ? (
          selected
        ) : (
          <span className="text-[#003333]">Select {title}</span>
        )}
      </div>
      {show === name && (
        <div className="absolute z-[70] mt-1 w-full  max-h-40 overflow-y-auto bg-white shadow-md rounded-md p-2 border border-[#DEE0E3]">
          <Input handleChange={handleSearch} placeholder={placeholder} />
          {loading ? (
            <div className="h-10 grid place-items-center my-10">
              {' '}
              <ImSpinner className="animate-spin" size={20} />
            </div>
          ) : (
            <>
              {data.length === 0 ? (
                <div
                  onClick={createFN}
                  className="cursor-pointer hover:bg-[#0A0F290A] rounded-[12px] p-2 mt-2"
                >
                  {' '}
                  Create {title}{' '}
                  <span className="font-semibold">"{`${value}`}"</span>
                </div>
              ) : (
                <div className="my-1 flex flex-col">
                  {data.map((doc) => (
                    <div
                      key={doc._id}
                      onClick={() => onSelect(doc)}
                      className="cursor-pointer hover:bg-[#0A0F290A] rounded-[12px] p-2"
                    >
                      {doc.name}
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
